import Carousel from 'react-bootstrap/Carousel';

import './storeLocation.scss';

import photo1 from '../../img/photo1.JPG';
import photo2 from '../../img/photo2.JPG';
import photo3 from '../../img/photo3.JPG';

const StoreLocation = () => {
    
    return (
        <div className="storeLocation">
            <div className="storeLocation__title">де ми знаходимося</div>
            <h3 className="storeLocation__storeNumer">перший магазин</h3>
            <div className="storeLocation__box">
                <Carousel>
                    <Carousel.Item>
                        <img src={photo1} alt="photo1" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={photo2} alt="photo2" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={photo3} alt="photo3" />
                    </Carousel.Item>
                </Carousel>          
                <iframe className="storeLocation__map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d522.9330923949215!2d35.2663123042143!3d48.659257711980054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d945b94337225d%3A0xb74dc67ac15a3383!2z0JHRg9C00LzQsNGC0LXRgNGW0LDQu9C4!5e0!3m2!1suk!2spl!4v1694964194450!5m2!1suk!2spl" width="571" height="428.25" style={{border: "0"}} title="Google Maps" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="storeLocation__subtitle">
                м. Новомосковськ вул. Гетьманська 241
            </div>
            <div className="storeLocation__secondStore">
                <h3 className="storeLocation__storeNumer">другий магазин</h3>
                <iframe className="storeLocation__secondMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2149.510163489425!2d35.289275195463404!3d48.741490157929974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d940da2d205fe3%3A0x5bda51b415648b7e!2z0LLRg9C7LiDQl9Cw0YLQuNGI0L3QsCwg0JLRltC70YzQvdC1LCDQlNC90ZbQv9GA0L7Qv9C10YLRgNC-0LLRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwg0KPQutGA0LDRl9C90LAsIDUxMjYw!5e0!3m2!1suk!2spl!4v1713455564677!5m2!1suk!2spl" title="Google Maps" width="571" height="428.25" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className="storeLocation__subtitle">
                    вул. Затишна, селище Вільне, Дніпропетровська область
                </div>
            </div>
        </div>
    )
}

export default StoreLocation;