import { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormControl } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

import logo from '../../img/logo.png';

import data from '../../info.json';

import './appHeader.scss';

const AppHeader = () => {
  const categories = data.categories;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const resultsRef = useRef(null);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleModalClick = useCallback((event) => {
    if (isModalOpen && event.target.classList.contains('header__modal')) {
      closeModal();
    }
  }, [isModalOpen, closeModal]);

  const handleScroll = useCallback((className) => {
    document.querySelector(`.${className}`).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }, []);

  const handleSearchChange = useCallback((event) => {
    const { value } = event.target;
    setSearchTerm(value);

    const results = value
      ? categories.flatMap((category) =>
          category.products.filter((product) =>
            product.name.toLowerCase().includes(value.toLowerCase())
          )
        )
      : [];

    setSearchResults(results);
    setSelectedCategoryId(null);
  }, [categories]);

  useEffect(() => {
    const closeResultsOnClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target) && !isModalOpen) {
        setSearchResults([]);
        resultsRef.current.style.display = 'none';
        setSearchTerm('');
      }
    };

    document.addEventListener('click', closeResultsOnClickOutside);

    return () => {
      document.removeEventListener('click', closeResultsOnClickOutside);
    };
  }, [isModalOpen]);

  const selectionMenu = () => {
    if (isModalOpen && selectedCategoryId !== null) {
      const selectedProduct = searchResults.find((product) => product.id === selectedCategoryId);
      if (!selectedProduct) {
        return null;
      }
      
      return (
        <div className="header__modal" onClick={handleModalClick}>
          <div className="header__content">
            <span onClick={() => setSelectedCategoryId(null)}>×</span>
            <div className="header__content-goodsRange">
              {Object.keys(selectedProduct.type).map((typeValue) => (
                <Dropdown key={typeValue}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedProduct.type[typeValue]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>{selectedProduct.size[typeValue]}</Dropdown.Item>
                    <Dropdown.Item>{`${selectedProduct.price[typeValue]} грн`}</Dropdown.Item>
                    <Dropdown.Item>{selectedProduct.inStock[typeValue] ? 'Є в наявності' : 'Немає в наявності'}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ))}
            </div>
            <div className="header__content-reminders">Для оформлення замовлення зверніться за одним із контактів:
            <div className="header__content-contacts">
                <p>
                    Дмитро: +38(099) 733-05-57 <br />
                    Олексій: +38(097) 409-24-62
                </p>
                <p>
                    Віталій: +38(068) 744-10-39 <br />
                    Сергій: +38(096) 803-58-60
                </p>
            </div>
          </div>
          </div>
        </div>
      );
    }
    return null;
  };
  
  return (
    <header className="header">
      <div className="header__box">
        <img src={logo} alt="logo" className="header__logo" />
        <div className="header__search">
          <Formik
            initialValues={{ search: '' }}
            onSubmit={(values, { resetForm }) => {
              console.log(values.search);
              resetForm();
            }}
          >
            {({ values, handleSubmit, handleChange, handleBlur }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  type="text"
                  name="search"
                  placeholder="пошук"
                  as={FormControl}
                  onChange={(e) => {
                    handleChange(e);
                    handleSearchChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.search}
                  autoComplete="off"
                />
                <ErrorMessage name="title">
                  {(errorMessage) => (
                    <div className="header__text-danger">{errorMessage}</div>
                  )}
                </ErrorMessage>
              </Form>
            )}
          </Formik>
          {searchTerm && (
            <div className="header__results" ref={resultsRef}>
              {searchResults.map((result) => (
                <div className="header__results-items" 
                key={result.id}
                onClick={() => {
                  setSelectedCategoryId(result.id);
                  openModal();
                }}>
                  {result.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="header__info">
          <div onClick={() => handleScroll('infoBlock')} className="header__info-links">контакти</div>
          <div onClick={() => handleScroll('storeDirectory__title')} className="header__info-links">товари</div>
          <div onClick={() => handleScroll('storeLocation')} className="header__info-links">локація</div>
        </div>
      </div>
      {selectionMenu()}
    </header>
  );
};

export default AppHeader;