import { Helmet } from 'react-helmet';

import AppHeader from "../appHeader/AppHeader";
import AboutCompany from "../aboutCompany/AboutCompany";
import StoreDirectory from "../storeDirectory/StoreDirectory";
import StoreLocation from "../storeLocation/StoreLocation";
import InfoBlock from "../infoBlock/InfoBlock";
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const App = () => {
  return (
    <>
      <Helmet>
          <title>Budhouse</title>
          <meta name="description" content="Магазин будівельних матеріалів" />
          <meta name="keywords" content="Магазин, будівельні матеріали, будівельні, матеріали" />
      </Helmet>
      
      <ErrorBoundary>
          <AppHeader />
          <AboutCompany />
          <StoreDirectory />
          <StoreLocation />
          <InfoBlock />
      </ErrorBoundary>
    </>
)
}

export default App;