import './aboutCompany.scss';

import firstImg from '../../img/house.jpg';
import secondImg from '../../img/shop.jpg';

const AboutCompany = () => {
    return (
      <div className="aboutCompany" aria-labelledby="aboutCompany_title">
        <h1 id="aboutCompany_title" className="aboutCompany_title">про нас</h1>
        <div className="aboutCompany__showcase">
          <img src={firstImg} alt="building materials store" loading="lazy" className="aboutCompany__firstImg" />
          <div className="aboutCompany__text">
            <div className="aboutCompany__text-name">БУД <br /> ХАУС</div>
            <div className="aboutCompany__text-descr">
              Надаємо якісні будівельні матеріали та послуги, ставлячи якість
              на перше місце вже більше 20 років.
            </div>
          </div>
          <img src={secondImg} alt="building materials store" loading="lazy" className="aboutCompany__secondImg" />
        </div>
      </div>
    )
}

export default AboutCompany;