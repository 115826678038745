
import './infoBlock.scss';

const InfoBlock = () => {
    const currentYear = new Date().getFullYear();;

    return (
        <footer className="infoBlock">
            <h5 className="infoBlock__storeNumber1">магазин у Новомосковську</h5>
            <div className="infoBlock__firstStore">
                <p>
                    м. Новомосковськ <br />
                    вул. Гетьманська 241
                </p>
                <p>
                    Дмитро: +38(099) 733-05-57 <br />
                    Максим: +38(095) 920-89-67
                </p>
                <p>
                    Віталій: +38(068) 744-10-39 <br />
                    Сергій: +38(096) 803-58-60
                </p>
            </div>
            <h5 className="infoBlock__storeNumber2">магазин у Вільному</h5>
            <div className="infoBlock__secondStore">
                <p>
                    вул. Затишна, селище Вільне, 
                    Дніпропетровська область
                </p>
                <p>
                    Ярослав: +38(097) 409-59-89
                </p>
            </div>
            <div className="infoBlock__rights">{`© ${currentYear} Будхаус. Усі права захищено.`}</div>
        </footer>
    )
}

export default InfoBlock;