import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import data from '../../info.json';

import './storeDirectory.scss';

const StoreDirectory = () => {
  const categories = data.categories;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleModalClick = (event) => {
    if (event.target.classList.contains('storeDirectory__modal')) {
      closeModal();
    }
  };

  const selectionMenu = () => {
    if (isModalOpen && selectedCategoryId !== null) {
      const selectedCategory = categories.find((category) => category.id === selectedCategoryId);
      if (!selectedCategory) {
        return null;
      }

      if (selectedProduct) {
        const productInfo = selectedCategory.products.find((product) => product.id === selectedProduct);

        return (
          <div className="storeDirectory__modal" onClick={handleModalClick}>
            <div className="storeDirectory__content">
              <div className='storeDirectory__content-closeGoods' onClick={() => setSelectedProduct(null)}>×</div>
              <div className="storeDirectory__content-goodsRange">
                {Object.keys(productInfo.type).map((typeValue) => (
                  <Dropdown key={typeValue}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {productInfo.type[typeValue]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>{productInfo.size[typeValue]}</Dropdown.Item>
                      <Dropdown.Item>{`${productInfo.price[typeValue]} грн`}</Dropdown.Item>
                      <Dropdown.Item>{productInfo.inStock[typeValue] ? 'Є в наявності' : 'Немає в наявності'}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
              </div>    
              <div className="storeDirectory__content-reminders">Для оформлення замовлення зверніться за одним із контактів:
              <div className="storeDirectory__content-contacts">
                  <p>
                      Дмитро: +38(099) 733-05-57 <br />
                      Максим: +38(095) 920-89-67
                  </p>
                  <p>
                      Віталій: +38(068) 744-10-39 <br />
                      Сергій: +38(096) 803-58-60
                  </p>
              </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="storeDirectory__modal" onClick={handleModalClick}>
          <div className="storeDirectory__content">
            <span onClick={closeModal}>×</span>
            {selectedCategory.products.map((product) => (
              <div
                key={product.id}
                className="storeDirectory__content-items"
                onClick={() => setSelectedProduct(product.id)}
              >
                <img src={product.icon} loading="lazy" alt="icons" />
                <h5>{product.name}</h5>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const setId = (id) => {
    setSelectedCategoryId(id);
    setSelectedProduct(null);
  };

  return (
    <div className="storeDirectory">
      <div className="storeDirectory__title">каталог товарів</div>
      <div className="storeDirectory__goods">
        {categories.map((category) => (
          <div
            key={category.id}
            className="storeDirectory__goods-item"
            onClick={() => {
              setId(category.id);
              openModal();
            }}
          >
            <img src={category.icon} alt="logo" />
            <h5>{category.name}</h5>
          </div>
        ))}
      </div>
      {selectionMenu()}
      <div className="storeDirectory__reminders">Для оформлення замовлення зв'яжіться з нами за наведеними нижче контактами</div>
    </div>
  );
};

export default StoreDirectory;
